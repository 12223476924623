import { orderBy } from 'lodash'
import { Vue } from 'vue-property-decorator'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import { ISurvey, ISurveyFilter } from '@/interfaces/survey'
import api from '@/api'

import store from '@/store'
import mainModule from '@/store/main'

@Module
export class SurveyModule extends VuexModule {
  // state
  items: ISurvey[] = []
  filters: { [name: string]: ISurveyFilter[] } = {}

  // getters
  orderedItems(): ISurvey[] {
    return orderBy(this.items, ['created_at'], ['desc'])
  }

  itemById(uuid: string): ISurvey {
    return this.items.filter((item) => item.id === uuid)[0]
  }

  filtersBySurveyId(uuid: string): ISurveyFilter[] | null {
    return this.filters[uuid] || null
  }

  // mutations
  @Mutation
  setItems(payload: ISurvey[]): void {
    this.items = payload
  }

  @Mutation
  setItem(payload: ISurvey): void {
    const items = this.items.filter((item: ISurvey) => item.id !== payload.id)
    items.push(payload)
    this.items = items
  }

  @Mutation
  deleteItem(payload: { id: string }): void {
    this.items = this.items.filter((item: ISurvey) => item.id !== payload.id)
  }

  @Mutation
  setFilter(payload: { uuid: string; filter: ISurveyFilter[] }): void {
    Vue.set(this.filters, payload.uuid, payload.filter)
  }

  @Action
  async actionLoad(): Promise<void> {
    try {
      const response = await api.survey.getAll(mainModule.token)
      if (response) {
        this.setItems(response.data)
      }
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionLoadPublicById(uuid: string): Promise<void> {
    try {
      const response = await api.survey.getOnePublic(uuid)
      if (response) {
        this.setItem(response.data)
      }
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionUpdate(item: ISurvey, callback?: (status: boolean) => void): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }

      mainModule.addNotification(loadingNotification)
      await api.survey
        .update(mainModule.token, item)
        .then((response) => {
          this.setItem(response.data)

          setTimeout(() => {
            mainModule.removeNotification(loadingNotification)
          }, 500)

          mainModule.addNotification({
            content: 'Changes successfully saved',
            color: 'success'
          })

          if (callback) {
            callback(true)
          }
        })
        .catch((error) => {
          setTimeout(() => {
            mainModule.removeNotification(loadingNotification)
          }, 500)

          mainModule.addNotification({
            content: "Can't save changes. Please try again",
            color: 'error'
          })
          console.log('error', error)

          if (callback) callback(false)
        })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionCreate(payload: ISurvey): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }
      mainModule.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.survey.create(mainModule.token, payload),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
        ])
      )[0]
      this.setItem(response.data)
      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'Survey successfully created',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionDelete(payload: ISurvey): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }
      mainModule.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.survey.delete(mainModule.token, payload.id!),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
        ])
      )[0]
      this.deleteItem({ id: payload.id! })
      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'Survey successfully deleted',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionLoadFilters(payload: ISurvey): Promise<void> {
    try {
      const response = await api.survey.getFiltersData(mainModule.token, payload.id!)
      if (response) {
        this.setFilter({ uuid: payload.id!, filter: response.data })
      }
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }
}

export const surveyModule = new SurveyModule({ store, name: 'survey' })
export default surveyModule
